<template>
	<div class="">
		<!-- sca-29kadfladgpq0d-alkdafps -->
		<div v-if="store.userLoggedIn === false" class="page-tile-holder">
			<div style="text-align: center; width: 100%; padding: 20px 20px 0;">
				<img src="@/assets/sca-logo-blue.png" style="height: 40px;cursor: pointer;" alt="SCA Logo" />
			</div>
			<LoginWidget :showgoogle="true" />
		</div>
		<div v-if="store.userLoggedIn === true">
			<SCALeftMenu></SCALeftMenu>
			<div class="rightside">
				<SCASummary v-if="store.innerPageView === 'sca-summary'"></SCASummary>
				<SCADash v-if="store.innerPageView === 'sca-dash'"></SCADash>

				<SCAFiveYear v-if="store.innerPageView === 'sca-fiveyear'"></SCAFiveYear>
				<SCAFiveYearGenderBars v-if="store.innerPageView === 'sca-fiveyeargender'"></SCAFiveYearGenderBars>

				<SCAQtrByCompetitor v-if="store.innerPageView === 'sca-qtrbycompetitor'"></SCAQtrByCompetitor>
				<SCAQtrBySurvey v-if="store.innerPageView === 'sca-qtrthislast'"></SCAQtrBySurvey>

				<SCAFMCompare v-if="store.innerPageView === 'sca-fmshare'" chosenstatistic="shareToAll"></SCAFMCompare>
				<SCAFMCompare v-if="store.innerPageView === 'sca-fmcume'" chosenstatistic="cume"></SCAFMCompare>
				<SCAFMCompare v-if="store.innerPageView === 'sca-fmtsl'" chosenstatistic="tslHrsDecimal"></SCAFMCompare>
				<SCAFMCompare v-if="store.innerPageView === 'sca-fmavg'" chosenstatistic="avgAud"></SCAFMCompare>
				<SCAFMCompare v-if="store.innerPageView === 'sca-fmnetworkscume'" chosenstatistic="cume"></SCAFMCompare>

				<SCADaypartReview v-if="store.innerPageView === 'sca-musicworkday'" :mktanddayparts="musicworkdayconfig" pagetitle="Mon-Fri 9am-3pm"></SCADaypartReview>
				<SCADaypartReview v-if="store.innerPageView === 'sca-doubletues'" :mktanddayparts="tuesworkdayconfig" pagetitle="Tue 9am-3pm"></SCADaypartReview>
				<SCADaypartReview v-if="store.innerPageView === 'sca-rnbfri'" :mktanddayparts="friworkdayconfig" pagetitle="Fri 9am-3pm"></SCADaypartReview>
				<SCADaypartReview v-if="store.innerPageView === 'sca-football'" :mktanddayparts="triplemfootballconfig" pagetitle="Triple M Football"></SCADaypartReview>

				<SCARadioTypes v-if="store.innerPageView === 'sca-radiotypes'"></SCARadioTypes>
				<SCAPOLBySurvey v-if="store.innerPageView === 'sca-polcume'"></SCAPOLBySurvey>
				<SCAPOLCompare v-if="store.innerPageView === 'sca-polcumebycomp'"></SCAPOLCompare>

				<SCARegionBySurvey v-if="store.innerPageView === 'sca-regioncume'"></SCARegionBySurvey>
				<SCARegionCompare v-if="store.innerPageView === 'sca-regioncompetitors'"></SCARegionCompare>

				<SCAHMLBySurvey v-if="store.innerPageView === 'sca-hmlcume'"></SCAHMLBySurvey>
				<SCAHMLCompare v-if="store.innerPageView === 'sca-hmlcompetitors'"></SCAHMLCompare>

				<SCASummary v-if="store.innerPageView === 'sca-football-summary'"
					:globaldemosonly="true" :daypartidsoverride="[99,100,101,102,103,104,105,106,107,108,109,110,111]"
					:excludesmbap="true"
				></SCASummary>
				<SCASummary v-if="store.innerPageView === 'sca-other-summary'"
					:globaldemosonly="true" :daypartidsoverride="[726,122,725,781,782,825,2345,2346]"
					:excludesmbap="false"
				></SCASummary>

				<SCAP1s v-if="store.innerPageView === 'sca-p1s'"></SCAP1s>
			</div>
		</div>
	</div>
</template>
<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import {defineComponent} from 'vue'
import SCALeftMenu from "@/components/views/SCALeftMenu.vue";
import SCASummary from "@/components/views/SCASummary.vue";
import SCADash from "@/components/views/SCADash.vue";
import SCAFMCompare from "@/components/views/SCAFMCompare.vue";
import SCAQtrByCompetitor from "@/components/views/SCAQtrByCompetitor.vue";
import SCAQtrBySurvey from "@/components/views/SCAQtrBySurvey.vue";
import SCADaypartReview from "@/components/views/SCADaypartReview.vue";
import SCARadioTypes from "@/components/views/SCARadioTypes.vue";
import SCAPOLCompare from "@/components/views/SCAPOLCompare.vue";
import SCAPOLBySurvey from "@/components/views/SCAPOLBySurvey.vue";
import SCAFiveYear from "@/components/views/SCAFiveYear.vue";
import SCARegionBySurvey from "@/components/views/SCARegionBySurvey.vue";
import SCARegionCompare from "@/components/views/SCARegionCompare.vue";
import LoginWidget from "@/components/LoginWidget.vue";
import SCAFiveYearGenderBars from "@/components/views/SCAFiveYearGenderBars.vue";
import SCAHMLBySurvey from "@/components/views/SCAHMLBySurvey.vue";
import SCAHMLCompare from "@/components/views/SCAHMLCompare.vue";
import SCAP1s from "@/components/views/SCAP1s.vue";

export default defineComponent({
	name: "DashSCA2",
	mixins: [globalMixin],
	components: {
		SCAP1s,
		SCAHMLCompare,
		SCAHMLBySurvey,
		SCAFiveYearGenderBars,
		LoginWidget,
		SCARegionCompare,
		SCARegionBySurvey,
		SCAFiveYear,
		SCAPOLBySurvey,
		SCAPOLCompare,
		SCARadioTypes,
		SCADaypartReview,
		SCAQtrBySurvey,
		SCAQtrByCompetitor,
		SCAFMCompare,
		SCADash,
		SCASummary,
		SCALeftMenu,

	},
	props: {},
	data() {
		return {
			store: store,

			musicworkdayconfig: [ //mon-fri 9a-3p
				{marketId: 110, daypartId: 47},
				{marketId: 111, daypartId: 47},
				{marketId: 112, daypartId: 47},
				{marketId: 113, daypartId: 47},
				{marketId: 114, daypartId: 47},
				{marketId: 900, daypartId: 47},
			],
			tuesworkdayconfig: [ //tue 9a-3p
				{marketId: 110, daypartId: 55},
				{marketId: 111, daypartId: 55},
				{marketId: 112, daypartId: 55},
				{marketId: 113, daypartId: 55},
				{marketId: 114, daypartId: 55},
				{marketId: 900, daypartId: 55},
			],
			friworkdayconfig: [ //fri 9a-3p
				{marketId: 110, daypartId: 56},
				{marketId: 111, daypartId: 56},
				{marketId: 112, daypartId: 56},
				{marketId: 113, daypartId: 56},
				{marketId: 114, daypartId: 56},
				{marketId: 900, daypartId: 56},
			],
			triplemfootballconfig: [
				{marketId: 110, daypartId: 57},
				{marketId: 111, daypartId: 58},
				{marketId: 112, daypartId: 57},
				{marketId: 113, daypartId: 59},
				{marketId: 114, daypartId: 60},
			],
		}
	},
	computed: {},
	methods: {},
	watch: {},
	mounted() {
		this.store.innerPageView = 'sca-summary'
	},
	created() {

	},
})
</script>
<style scoped>
.rightside {
	margin-left: 300px;
}
</style>