import { reactive } from 'vue'

let apiUrl = 'https://platform.audology.app/api/dashboards/';
//home main page https://dashboards.audology.app
let isLocalhost = false
if(window.location.origin.indexOf("localhost") > -1 || window.location.origin.indexOf("loc.") > -1) {
	apiUrl = 'http://audology.test/api/dashboards/';
	isLocalhost = true
}

export const store = reactive({
	isLocalhost: isLocalhost,

	apiUrl: apiUrl,
	dashId: null,
	dashOb: null,
	skin: '',
	notYetFirstLoadDone: true,
	returnedData: null,
	returnedDataInputs: null, //for P1Reports
	returnedQtrData: null,
	returnedPOLData: null,
	configData: null,
	stationObs: [],
	demoObs: [],
	surveyObs: [],
	marketObs: [], //110=syd, 111=mel, 112=bris, 113=adl, 114=per, 900=smbap
	statisticObs: [],
	daypartObs: [],
	qtrHrObs: [],
	innerPageView: 'home',

	scaChosenMarketId: 110,
	scaChosenStationId: 'g_201056',
	scaChosenBrands: ['hit','mmm','kiis','nova','puregold','smooth','jjj'],
	scaChosenDemoGroupId: 'People',
	scaChosenDemoId: 48,
	scaChosenDaypartId: 1,
	scaChosenStatistic: 'cume',
	scaChosenQtrHrDaypart: 92,

	audologyAPIUrl: 'https://platform.audology.app/api/',

	previewDevMode: false,
	userLoggedIn: false,

	showCommas: true,

	chartColourGroup: [
		'#3498DB','#27AE60','#D4AC0D','#D35400','#616A6B','#F1948A','#222222','#D16d77','#226622'
	],
	chartColourGroupARNFirst: [
		'#553AA9','#3498DB','#27AE60','#D4AC0D','#D35400','#616A6B','#F1948A'
	],
	chartColourGroupGradient: [
		'#7bb7db', '#7295d9', '#6c74d9',
		'#7f6bda', '#9d6bda', '#bf6ddb',
		'#d16dcd', '#d06caa', '#d16d77',
		'#cf6b87', '#d18c6b', '#d8af71',
		'#85de70'
	],
	netcolors: [
		{key: 'arn_k', col: '#e50076'},
		{key: 'arn', col: '#e50076'},
		{key: 'arn_p', col: '#EDCA62'},
		{key: 'sca_h', col: '#1bcfc9'},
		{key: 'sca', col: '#1bcfc9'},
		{key: 'sca_m', col: '#fc6402'},
		{key: 'abc_loc', col: '#000000'},
		{key: 'abc_j', col: '#f33e2a'},
		{key: 'nine', col: '#092e52'},
		{key: 'nova', col: '#eb1c24'},
		{key: 'novaent', col: '#eb1c24'},
		{key: 'nova_n', col: '#eb1c24'},
		{key: 'nova_s', col: '#005094'},
		{key: 'nova_aa', col: '#2963FF'},
		{key: 'ace', col: '#96356e'},
		{key: 'cada', col: '#718fff'},
		{key: 'abc_j_scaoverride', col: '#000000'},
		{key: 'arn_k_scaoverride', col: '#BBBBBB'},
		{key: 'sca_m_scaoverride', col: '#f5540a'},
		{key: 'sca_h_scaoverride', col: '#0a25f5'},
		{key: '4bh', col: '#f3bb51'},
		{key: '2ue', col: '#34bec9'},
		{key: '3mp', col: '#96356e'},
		{key: 'magic', col: '#e2622d'},
		{key: 'sen', col: '#85de70'},
	],
	defaultColors: ['#FFD034','#FD9549','#FF6767','#FF26CD','#AF43FF','#2963FF','#66CAFF','#00A7FF','#d45087','#a62166','#42d534'],
	defaultColorsOld: ['#003f5c','#2f4b7c','#665191','#a05195','#d45087','#f95d6a','#ff7c43','#ffa600','#a62166','#42d534'],
	femaleMaleColors:  ['#e50076','#0055a5'],
	defaultGreyColors: ['#888888','#BBBBBB'],
	defaultGreyActiveColor: '#444444',
	defaultARNColors: ['#4e39a3', '#4e39a3'], //4e39a3, 781499
	defaultARNActiveColor: '#e50076',
	defaultSCASplitColors: ['#FF0000','#FFFF00','#0000FF','#000000','#888888'],

	places: [
		{id: 0, name: 'All', type: 'place'},
		{id: 1, name: 'Home', type: 'place'},
		{id: 2, name: 'Car', type: 'place'},
		{id: 3, name: 'Work', type: 'place'},
		{id: 4, name: 'Elsewhere', type: 'place'},
		// {id: 9, name: 'Not stated', type: 'place'},
	],

	demosSuperGlobal: [ //eg for streaming
		48, //P10+
		49, //M10+
		50, //F10+
		78, //P25-54
		151, //M25-54
		152, //F25-54
		// 255, //PGB10+
		// 256, //MGB10+
		// 257, //FGB10+
		// 535, //PGB25-54
		65, //P25-39
		117, //M25-39
		118, //F25-39
		475, //P25-64
		9548, //M25-64
		9549, //F25-64
		66, //P40-54
		238, //M40-54
		239, //F40-54
		263, //P35-54
		265, //M35-54
		266, //F35-54
		98, //P10-39
		196, //M10-39
		195, //F10-39
		67, //P40+
		71, //M40+
		74, //F40+
	],

	demoGroupsNova: [
		{key: 'People', dids: [48,860,9010,372,78,1831,67,63,98,64,111,65,66,372,9024]},
		{key: 'Males', dids: [49,9013,9015,373,151,1833,238,71,9025]},
		{key: 'Females', dids: [50,9012,9014,374,152,1832,239,74,9026]},
	],

	demoGroupsSCA: [
		{key: 'People', dids: [48,78,63,98,64,9404,10183,111,9670,9024,65,372,475,377,9027,9029,250,1326,263,1831,67,9035,66,264,9004,458,9009,9042,431,9047]},
		{key: 'Males', dids: [49,151,9023,196,803,9405,10184,70,9671,9025,117,373,9548,375,9028,9031,1600,1321,265,1833,71,9036,238,269,9006,9039,9040,9044,9046,9048]},
		{key: 'Females', dids: [50,152,9022,195,73,9406,10185,532,9672,9026,118,374,9549,376,2316,9032,1599,1322,266,1832,74,9037,239,270,9005,9038,9041,9043,9045,9049]},
	],
	demoGroupsSCACore: [
		{key: 'People', dids: [48,63,98,64,111,9010,110,65,372,78,475,263,1831,66,67,68]},
		{key: 'Males', dids: [49,9023,803,69,117,151,9548,265,238,71,158]},
		{key: 'Females', dids: [50,9022,532,72,118,152,9549,266,239,74,159]},
	],

	demoGroupsARN: [
		{key: 'People', dids: [48,98,9050,111,64,9024,65,372,78,250,263,1831,9035,66,9004,68]},
		{key: 'Males', dids: [49,196,9051,70,803,9025,117,373,151,1600,265,1833,9036,238,9006,158]},
		{key: 'Females', dids: [50,195,9052,73,532,9026,118,374,152,1599,266,1832,9037,239,9005,159]},
	],

	demoGroupsARN_SMBAP: [
		{key: 'People', dids: [48,98,111,64,65,372,78,263,1831,66,68]},
		{key: 'Males', dids: [49,196,803,117,373,151,265,1833,238,158]},
		{key: 'Females', dids: [50,195,532,118,374,152,266,1832,239,159]},
	],

	demoGroupsAce: [
		{key: 'People', dids: [48,78,255,535,110,64,65,372,9027,1326,263,66,67,9004,458,181,68,98,9225,182,431,9047]},
		{key: 'Males', dids: [49,151,256,9056,69,803,117,373,9028,1321,265,238,71,9006,9039,271,158,196,9226,273,9046,9048]},
		{key: 'Females', dids: [50,152,257,9055,72,532,118,374,2316,1322,266,239,74,9005,9038,272,159,195,9227,274,9045,9049]},
	],

	daypartIdsSCA: [1,3,17,4,47,26,24,89,23,6,38,7,48,39,49,50,18,51,1277,52,54,55,56, //standards
		171,172,65,173,53,174,175,70,176,177 //weekends
	],

	daypartsGlobal: [ //for streaming etc
		1, //MS 530a-12mn
		2, //MS 12a-12a
		3, //MF 530a-9a
		4, //MF 9a-12n
		5, //MF 12nn-4p
		6, //MF 4p-7p
		7, //MF 7p-12mn
		8, //Weekends 12a-12a
		17, //MF 6am-9am
		18, //SS 530am-12mn
		19, //MF 530am-7pm
		20, //MF 9a-4p
		23, //MF 4p-6p
		24, //MF 3p-4p
		25, //MF 5a-9a
		26,	//MF 12n-3p
		27, //MF 3p-7p
		28, //MS 530a-7p
		29, //SS 530a-7p
		30, //MF 530a-12mn
		89, //MF 3p-6p
		47, //MF 9a-3p
		38, //MF 6pm-7pm
	],

	daypartsGlobalSCA: [
		1, //MS 530a-12mn
		3, //MF 530a-9a
		17, //MF 6a-9a
		4, //MF 9a-12n
		26,	//MF 12n-3p
		47, //MF 9a-3p
		24, //MF 3p-4p
		89, //MF 3p-6p
		6, //MF 4p-7p
		23, //MF 4p-6p
		38, //MF 6pm-7pm
		7, //MF 7p-12mn
		18, //SS 530am-12mn
	],

	marketListSCA: [
		{id: 110, name: 'Sydney'},
		{id: 111, name: 'Melbourne'},
		{id: 112, name: 'Brisbane'},
		{id: 113, name: 'Adelaide'},
		{id: 114, name: 'Perth'},
		{id: 19, name: 'Newcastle'},
		{id: 14, name: 'Gold Coast'},
		{id: 72, name: 'Canberra'},
	],

	marketListNova: [
		{id: 110, name: 'Sydney'},
		{id: 111, name: 'Melbourne'},
		{id: 112, name: 'Brisbane'},
		{id: 113, name: 'Adelaide'},
		{id: 114, name: 'Perth'},
	],

	brandstations: {
		hit: ['g_201056','g_301057','g_701056','g_801056','g_8902','g_403003','g_427036','g_402003','c_2034'],
		mmm: ['g_2209','g_3208','g_7206','g_8204','g_901012','g_403011','g_427001','g_4004','c_2035'],
		kiis: ['g_201009','g_301056','g_7203','g_8203','g_8905','g_4304','g_4817','c_2030'],
		nova: ['g_2217','g_3217','g_7214','g_8213','g_8914','c_2117'],
		puregold: ['g_2207','g_3207','g_8214','g_4301','c_2032'],
		smooth: ['g_2222','g_3223','g_701025','g_801024','g_901021','c_2041'],
		jjj: ['g_2213','g_3213','g_7210','g_8209','g_8910','g_4308','g_4813','g_4008','c_120'],
		bh: ['g_701014'],
		aa: ['g_8201'],
	},

	fiveYearDemoIds:[9068,9069,9070,9071,9072,9073,9074,9075,9076,9077,9078,9079],
	fiveYearDemoIdsMale:[9145,9146,9147,9148,9149,9150,9151,9152,9153,9154,9155,9156],
	fiveYearDemoIdsFemale:[9174,9173,9172,9171,9170,9169,9168,9167,9166,9165,9164,9163],

	regionSplitDemoIds: [
		{mktid: 110, dids: [9100,9101,9102,9103,9104]},
		{mktid: 111, dids: [9092,9093,9094,9095]},
		{mktid: 112, dids: [9087,9088,9089,9090,9091]},
		{mktid: 113, dids: [9083,9084,9085,9086]},
		{mktid: 114, dids: [9096,9097,9098,9099]},
		{mktid: 19, dids: [9105,9106,9107]},
		{mktid: 14, dids: [9108,9109]},
	],

	hmlDemos: [
		9110, //light
		9111, //medium
		9112, //heavy
	],

})