<template>
	<div style="width: 100%; max-width: 1200px;">
		<div>
			<div style="margin-bottom: 20px;" class="selection-holders">
				<select v-model="chosenSurveyId" class="select-styled">
					<option v-for="surv in surveyOptions" :key="surv.id" :value="surv.id">{{surv.name}}</option>
				</select>
				<select v-model="chosenDemoId" class="select-styled">
					<option v-for="dem in demoOptions" :key="dem.id" :value="dem.id">{{dem.name}}</option>
				</select>
				<select v-model="chosenDaypartId" class="select-styled">
					<option v-for="dp in daypartOptions" :key="dp.id" :value="dp.id">{{dp.name}}</option>
				</select>
				<select v-model="chosenStationId" class="select-styled">
					<option v-for="st in stationOptions" :key="st.id" :value="st.id">{{st.name}}</option>
				</select>
			</div>

			<div class="pies-holder">
				<ChartHolderDash :height="360" addclasses="half"
					charttitle="P1/Non P1 Cume Split"
					:source="srcStr+'Percent of station cume in daypart that are '+stnName+' P1s/Non P1'"
				>
					<template v-slot:default>
						<StationPie v-if="stationDataset" :series="p1CumeSplitData.series" :labels="p1CumeSplitData.labels"></StationPie>
					</template>
				</ChartHolderDash>
				<ChartHolderDash :height="360" addclasses="half"
					charttitle="P1/Non P1 Listening Volume Split"
					:source="srcStr+'Percent of amount of station listening in daypart from '+stnName+' P1s/Non P1'"
				>
					<template v-slot:default>
						<StationPie v-if="stationDataset" :series="p1ListenSplitData.series" :labels="p1ListenSplitData.labels"></StationPie>
					</template>
				</ChartHolderDash>
			</div>

			<div>
				<ChartHolderDash :height="360"
					:charttitle="'Where else are '+stnName+' Cumers listening?'"
					:source="srcStr+'Percent of '+stnName+' Cume that listens to each station in Daypart - where else are '+stnName+' Cumers listening?'"
				>
					<template v-slot:default>
						<ApexFreqBarP1 v-if="stationDataset" :chartdata="pctCumeOthersChart" :fetching="fetching"></ApexFreqBarP1>
					</template>
				</ChartHolderDash>
				<ChartHolderDash :height="360"
					:charttitle="'Where else are '+stnName+' P1s listening?'"
					:source="srcStr+'Percent of '+stnName+' P1s that listens to each station in Daypart'"
				>
					<template v-slot:default>
						<ApexFreqBarP1 v-if="stationDataset" :chartdata="pctP1sCumeOthersChart" :fetching="fetching"></ApexFreqBarP1>
					</template>
				</ChartHolderDash>
				<ChartHolderDash :height="360"
					:charttitle="'What percentage '+stnName+' Cumers listening is given to each station for the selected daypart?'"
					:source="srcStr+'How much (volume) listening (percent) in daypart is given to each station by '+stnName+' Cumers'"
				>
					<template v-slot:default>
						<ApexFreqBarP1 v-if="stationDataset" :chartdata="pctListenToOtherStationsByCume" :fetching="fetching"></ApexFreqBarP1>
					</template>
				</ChartHolderDash>
				<ChartHolderDash :height="360"
					:charttitle="'What percentage '+stnName+' P1s listening is given to each station for the selected daypart?'"
					:source="srcStr+'How much (volume) listening (percent) in daypart is given to each station by '+stnName+' P1s'"
				>
					<template v-slot:default>
						<ApexFreqBarP1 v-if="stationDataset" :chartdata="pctListenToOtherStationsByP1" :fetching="fetching"></ApexFreqBarP1>
					</template>
				</ChartHolderDash>

				<ChartHolderDash :height="360" :charttitle="stnName+' Trending Lookup'">
					<template v-slot:headerRight>
						<select v-model="trendReportTypeSelection" class="select-styled">
							<option v-for="tr in trendReportOptions" :key="tr.id" :value="tr">{{tr.label}}</option>
						</select>
						<select v-if="trendNeedsAltStationSelection" v-model="trendAltStationIdSelection" class="select-styled" style="margin-left: 10px;">
							<option v-for="st in stationOptionsAlt" :key="st.id" :value="st.id">{{st.name}}</option>
						</select>
					</template>
					<template v-slot:default>
						<ApexLineTrend v-if="trendApexData" :chartdata="trendApexData" :categories="['Dayparts']" :fetching="fetching"></ApexLineTrend>
					</template>
				</ChartHolderDash>

			</div>

			<ChartHolderDash v-if="stationDataset && stationDataset.quintiles" :height="360"
				charttitle="Volume of listening by quintile"
				:source="srcStr+'Percent of station listening by each 20% of listeners (heaviest station listeners to lightest) in daypart. ' +
				'Each quintile has the same number of listeners. Quintile 1 is the heaviest listeners, quintile 5 is the lightest listeners.'"
			>
				<template v-slot:default>
					<ApexStackedBar v-if="stationDataset && stationDataset.quintiles" :chartdata="stationDataset.quintiles" :categories="['Quintiles']" :fetching="false"></ApexStackedBar>
				</template>
			</ChartHolderDash>

		</div>

	</div>
</template>
<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import {defineComponent} from 'vue'
import StationPie from "@/components/StationPie.vue";
import ChartHolderDash from "@/components/ChartHolderDash.vue";
import axios from "axios";
import ApexFreqBarP1 from "@/components/ApexFreqBarP1.vue";
import ApexStackedBar from "@/components/ApexStackedBar.vue";
import ApexLineTrend from "@/components/ApexLineTrend.vue";

export default defineComponent({
	name: "P1ReportPage",
	mixins: [globalMixin],
	components: {
		ApexLineTrend,
		ApexStackedBar,
		ApexFreqBarP1,
		ChartHolderDash,
		StationPie,
	},
	props: {
		isace: {
			type: Boolean,
			default: false
		},
	},
	data: function () {
		return {
			store: store,

			chosenDemoId: null,
			chosenSurveyId: null,
			chosenDaypartId: null,
			chosenStationId: null,

			returnTrendDataByMktId: {
				110: null,
				111: null,
				112: null,
				113: null,
				114: null,
				14: null,
				19: null,
				72: null,
			},

			fetching: true,

			trendReportOptions: [
				{id: 'p1CumeSplit', label: 'P1 Cume %'},
				{id: 'p1ListenSplit', label: 'P1 Listening %'},
				{id: 'pctCumeOtherStations', label: '% of Cume to Other'},
				{id: 'pctCumeOtherStationsByP1s', label: '% of P1s to Other'},
				{id: 'pctListenToOtherStationsByCume', label: '% Listening to Other'},
				{id: 'pctListenToOtherStationsByP1s', label: '% Listening to Other from P1s'},
			],
			trendReportTypeSelection: null,
			trendAltStationIdSelection: null,
		}
	},
	computed: {
		trendNeedsAltStationSelection() {
			let ar = ['pctCumeOtherStations', 'pctCumeOtherStationsByP1s', 'pctListenToOtherStationsByCume', 'pctListenToOtherStationsByP1s']
			if(this.trendReportTypeSelection && ar.includes(this.trendReportTypeSelection.id)) {
				return true
			}
			return false
		},
		trendApexData() {
			let mysurv = this.store.surveyObs.find(item => item.id === this.chosenSurveyId)
			if(mysurv) {
				let mktid = mysurv.mktid
				if(this.returnTrendDataByMktId[mktid]) {
					let lookupName = this.trendReportTypeSelection.id
					let myd = this.returnTrendDataByMktId[mktid]
					if(myd[lookupName] && myd[lookupName][this.chosenStationId]) {
						if(myd[lookupName][this.chosenStationId][this.chosenDemoId] && myd[lookupName][this.chosenStationId][this.chosenDemoId][this.chosenDaypartId]) {
							let mydata = myd[lookupName][this.chosenStationId][this.chosenDemoId][this.chosenDaypartId]
							if(!this.trendNeedsAltStationSelection) {
								return [{name: 'Pct', data: mydata}]
							}
							else {
								let mynewdata = mydata[this.trendAltStationIdSelection]
								if(mynewdata) return [{name: 'Pct', data: mynewdata}]
							}
						}
					}
				}
			}
			return null
		},
		stationOptionsAlt() {
			return this.stationOptions.filter(item => item.id !== this.chosenStationId)
		},
		stnob() {
			if(this.chosenStationId) {
				return this.store.stationObs.find(item => item.id === this.chosenStationId)
			}
			return null
		},
		stnName() {
			if(this.stnob) {
				return this.stnob.name
			}
			return 'Station'
		},
		srcStr() {
			let mysurv = this.store.surveyObs.find(item => item.id === this.chosenSurveyId)
			let mydemo = this.store.demoObs.find(item => item.id === this.chosenDemoId)
			let mydp = this.store.daypartObs.find(item => item.id === this.chosenDaypartId)
			if(mydp && mydemo && mysurv) {
				return mysurv.name + ', ' + mydemo.name + ', ' + mydp.name + '. '
			}
			return ''
		},
		p1CumeSplitData() {
			if(this.stationDataset && this.stnob) {
				return this.stationDataset.p1CumeSplit
			}
			return null
		},
		p1ListenSplitData() {
			if(this.stationDataset && this.stnob) {
				return this.stationDataset.p1ListenSplit
			}
			return null
		},
		pctCumeOthersChart() {
			let ser = {name: 'Percent of Cume', data: []}
			let counter = 0
			for(let dp of this.stationDataset.pctCumeOtherStations) {
				if(counter < 10) {
					let stob = this.store.stationObs.find(item => item.id === dp.x)
					if(stob) dp.x = stob.name
					ser.data.push(dp)
				}
				counter++
			}
			return [ser]
		},
		pctP1sCumeOthersChart() {
			let ser = {name: 'Percent of P1s', data: []}
			let counter = 0
			for(let dp of this.stationDataset.pctCumeOtherStationsByP1s) {
				if(counter < 10) {
					let stob = this.store.stationObs.find(item => item.id === dp.x)
					if(stob) dp.x = stob.name
					ser.data.push(dp)
				}
				counter++
			}
			return [ser]
		},
		pctListenToOtherStationsByCume() {
			let ser = {name: 'Percent of Listening', data: []}
			let counter = 0
			for(let dp of this.stationDataset.pctListenToOtherStationsByCume) {
				if(counter < 10) {
					let stob = this.store.stationObs.find(item => item.id === dp.x)
					if(stob) dp.x = stob.name
					ser.data.push(dp)
				}
				counter++
			}
			return [ser]
		},
		pctListenToOtherStationsByP1() {
			let ser = {name: 'Percent of Listening', data: []}
			let counter = 0
			for(let dp of this.stationDataset.pctListenToOtherStationsByP1s) {
				if(counter < 10) {
					let stob = this.store.stationObs.find(item => item.id === dp.x)
					if(stob) dp.x = stob.name
					ser.data.push(dp)
				}
				counter++
			}
			return [ser]
		},
		surveyOptions() {
			let survs = []
			if(this.store.returnedDataInputs && this.store.returnedDataInputs.surveys) {
				if(this.isace) {
					survs = this.store.surveyObs.filter(item => [110,111,112].includes(item.mktid) && this.store.returnedDataInputs.surveys.includes(item.id))
				}
				else {
					survs = this.store.surveyObs.filter(item => this.store.returnedDataInputs.surveys.includes(item.id))
				}
			}
			return survs
		},
		demoOptions() {
			let demos = []
			if(this.store.returnedDataInputs && this.store.returnedDataInputs.demographics) {
				demos = this.store.demoObs.filter(item => this.store.returnedDataInputs.demographics.includes(item.id))
			}
			return demos
		},
		daypartOptions() {
			let dps = []
			if(this.store.returnedDataInputs && this.store.returnedDataInputs.dayparts) {
				dps = this.store.daypartObs.filter(item => this.store.returnedDataInputs.dayparts.includes(item.id))
			}
			return dps
		},
		stationOptions() {
			let st = []
			for(let s in this.myDataset) {
				st.push(this.store.stationObs.find(item => item.id === s))
			}
			st.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
			return st
		},
		myDataset() {
			if(this.chosenDemoId && this.chosenSurveyId && this.chosenDaypartId) {
				if(this.store.returnedData[this.chosenSurveyId]) {
					if(this.store.returnedData[this.chosenSurveyId][this.chosenDemoId]) {
						if(this.store.returnedData[this.chosenSurveyId][this.chosenDemoId][this.chosenDaypartId]) {
							return this.store.returnedData[this.chosenSurveyId][this.chosenDemoId][this.chosenDaypartId]['results']
						}
					}
				}
			}
			return null
		},
		stationDataset() {
			if(this.myDataset && this.chosenStationId && this.myDataset[this.chosenStationId]) {
				return this.myDataset[this.chosenStationId]
			}
			return null
		},
	},
	methods: {
		selectFirstIfNull() {
			if(this.store.returnedData && !this.chosenDemoId) {
				this.chosenDemoId = this.store.returnedDataInputs.demographics[0]
			}
			if(this.store.returnedData && !this.chosenSurveyId) {
				let mysurv = this.store.surveyObs.find(item => item.mktid === 110)
				this.chosenSurveyId = mysurv.id // this.store.returnedDataInputs.surveys[0]
			}
			if(this.store.returnedData && !this.chosenDaypartId) {
				this.chosenDaypartId = this.store.returnedDataInputs.dayparts[0]
			}
			if(this.store.returnedData && (!this.chosenStationId || !this.stationOptions.find(item => item.id === this.chosenStationId)) && this.stationOptions.length > 0) {
				this.chosenStationId = this.stationOptions[0].id
			}
			this.getTrendData()
		},
		getData() {
			this.fetching = true
			let self = this
			let mkts = [110,111,112,113,114,14,19,72]
			if(this.store.dashOb.id === 1) mkts = [110,111,112,113,114]
			let postjson = JSON.stringify({
				apicreds: this.store.dashOb.audologyAPICreds,
				marketIds: mkts,
				lastXSurveys: 1,
				dayparts: [2,3,6,20,18],
				demographics: [48,78,151,152],
			})
			let api = this.store.audologyAPIUrl
			axios.post(api+"dashboards/station-p1-cache", postjson).then(function (response) {
				self.store.returnedData = response.data.data
				self.store.returnedDataInputs = response.data.inputs
				self.fetching = false
				self.getTrendData()
			}).catch(function (error) {
				console.log(error)
			});
		},
		getTrendData() {
			let mysurv = this.store.surveyObs.find(item => item.id === this.chosenSurveyId)
			if(mysurv && !this.fetching) {
				let mktid = mysurv.mktid
				if(this.returnTrendDataByMktId[mktid] === null) {
					this.fetching = true
					let self = this
					let postjson = JSON.stringify({
						apicreds: this.store.dashOb.audologyAPICreds,
						marketIds: [mktid],
						lastXSurveys: 8,
						dayparts: [2,3,6,20,18],
						demographics: [48,78,151,152],
					})
					let api = this.store.audologyAPIUrl
					axios.post(api+"dashboards/station-p1-trend", postjson).then(function (response) {
						self.returnTrendDataByMktId[mktid] = response.data.data[mktid]
						self.fetching = false
					}).catch(function (error) {
						console.log(error)
						self.fetching = false
					});
				}
			}
		},
	},
	watch: {
		'store.returnedData': {
			handler() {
				this.selectFirstIfNull()
			}
		},
		chosenSurveyId() {
			this.selectFirstIfNull()
		},
		trendNeedsAltStationSelection() {
			if(this.trendNeedsAltStationSelection === true && this.trendAltStationIdSelection === null) {
				this.trendAltStationIdSelection = this.stationOptionsAlt[0].id
			}
		},
	},
	mounted() {
		this.trendReportTypeSelection = this.trendReportOptions[0]
		this.getData()
	},
	unmounted() {
		this.store.returnedData = null
		this.store.returnedDataInputs = null
	}
})
</script>
<style scoped>
.pies-holder {
	margin-top: 30px;
	display: flex;
	width: 1200px;
	gap: 2%;
}
.selection-holders {
	display: flex;
	gap: 20px;
}
.select-styled {
	display: block;
}
</style>