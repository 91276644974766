<template>
	<div style="position: relative; width: 100%;">
		<div style="display: flex; justify-content: flex-start; gap: 15px;">
			<div>
				<select class="select-styled" v-model="chosenMarket">
					<option v-for="opt in marketList" :key="opt.id" :value="opt.id">{{opt.name}}</option>
				</select>
			</div>
			<div>
				<select class="select-styled" v-model="chosenDaypart">
					<option v-for="opt in dayparts" :key="opt.id" :value="opt.id">{{opt.name}}</option>
				</select>
			</div>
			<div>
				<select class="select-styled" v-model="chosenDemoGroup">
					<option v-for="demo in demoGroupsShortcut" :value="demo.key" :key="demo.key">{{demo.key}}</option>
				</select>
			</div>
			<div>
				<select class="select-styled" v-model="chosenDemographic">
					<option v-for="demo in demoDisplay" :value="demo.id" :key="demo.id">{{demo.name}}</option>
				</select>
			</div>
			<div>
				<select class="select-styled" v-model="chosenStatistic">
					<option value="shareToAll">Share</option>
					<option value="cume">Cume (000s)</option>
					<option value="tslHrsDecimal">TSL</option>
					<option value="avgAud">Avg Aud (000s)</option>
					<option value="cumePct">Cume %</option>
				</select>
			</div>
			<div>
				<select class="select-styled" v-model="cadence">
					<option value="survey">By Survey</option>
					<option value="year">By Year</option>
				</select>
			</div>
			<div>
				<select class="select-styled" v-model="chosenStartYear">
					<option v-for="yr in yearOptions" :value="yr" :key="yr">{{yr}}</option>
				</select>
			</div>
			<div style="display: flex; align-items: center;"><span> to </span></div>
			<div>
				<select class="select-styled" v-model="chosenEndYear">
					<option v-for="yr in yearOptions" :value="yr" :key="yr">{{yr}}</option>
				</select>
			</div>
			<div v-if="iscra === true">
				<select class="select-styled" v-model="chosenRadioType">
					<option :value="0">Total Radio</option>
					<option :value="1">AM/FM/DAB</option>
					<option :value="2">Streaming</option>
				</select>
			</div>
		</div>

		<div style="margin: 20px 0 20px;">
			<div style="margin-bottom: 10px; display: flex; justify-content: space-between;">
				<span class="netshortcut" @click="showStationSelectOverlay = true">Choose Stations</span>
				<div v-if="chosenMarket === 114" data-marketname="Perth">
					<span class="netshortcut" @click="chosenStations = perthDefaultStations">Commercial</span>
				</div>
				<div v-if="chosenMarket === 113" data-marketname="Adelaide">
					<span class="netshortcut" @click="chosenStations = adelaideDefaultStations">Commercial</span>
				</div>
				<div v-if="chosenMarket === 112" data-marketname="Brisbane">
					<span class="netshortcut" @click="chosenStations = brisbaneDefaultStations">Commercial All</span>
				</div>
				<div v-if="chosenMarket === 111" data-marketname="Melbourne">
					<span class="netshortcut" @click="chosenStations = melbourneDefaultStations">Commercial FMs</span>
				</div>
				<div v-if="chosenMarket === 110" data-marketname="Sydney">
					<span class="netshortcut" @click="chosenStations = sydneyDefaultStations">Commercial FMs</span>
				</div>
				<div v-if="chosenMarket === 900">
					<span class="netshortcut" @click="chosenStations = smbapDefaultStations">Networks excl DAB</span>
					<span class="netshortcut" @click="chosenStations = ['c_110','c_2042','c_105','c_2036']">Networks incl DAB</span>
					<span class="netshortcut" @click="chosenStations = ['c_2117','c_2030','c_2034','c_2041','c_2032','c_2035']">Sub Networks</span>
				</div>
				<div v-if="chosenMarket === 14" data-marketname="Gold Coast">
					<span class="netshortcut" @click="chosenStations = goldcoastDefaultStations">Commercial FMs</span>
				</div>
				<div v-if="chosenMarket === 72" data-marketname="Canberra">
					<span class="netshortcut" @click="chosenStations = canberraDefaultStations">Commercial Stations</span>
				</div>
				<div v-if="chosenMarket === 14" data-marketname="Newcastle">
					<span class="netshortcut" @click="chosenStations = newcastleDefaultStations">Commercial Stations</span>
				</div>
<!--				<div>-->
<!--					<span class="netshortcut" @click="selectRecentSurveys(20)">Recent</span>-->
<!--					<span class="netshortcut" @click="selectAllSurveys">All Surveys</span>-->
<!--				</div>-->
			</div>
<!--			<div v-for="st in stationsInMarket" :key="st.id" style="display: inline-block; margin-bottom: 8px;">-->
<!--				<input style="margin-right: 5px;" type="checkbox" v-model="chosenStations" :id="'stn'+st.id" :value="st.id" />-->
<!--				<label style="margin-right: 15px;" :for="'stn'+st.id">{{st.name}}</label>-->
<!--			</div>-->
<!--			<div v-if="chosenMarket !== 900">-->
<!--				<div v-for="st in combosInMarket" :key="st.id" style="display: inline-block; margin-bottom: 8px;">-->
<!--					<input style="margin-right: 5px;" type="checkbox" v-model="chosenStations" :id="'stn'+st.id" :value="st.id" />-->
<!--					<label style="margin-right: 15px;" :for="'stn'+st.id">{{st.name}}</label>-->
<!--				</div>-->
<!--			</div>-->
<!--			<span class="netshortcut" @click="showStationSelectOverlay = true">Choose Stations</span>-->

			<div v-if="showStationSelectOverlay" class="loader-overlay">
				<div class="stationselect-popup whitebg">
					<div class="popup-topbar">
						<span class="boldme">Choose Stations</span>
						<span class="closer" @click="showStationSelectOverlay = false"><i class="mdi mdi-close"></i></span>
					</div>
					<div style="width: 100%;" class="popup-insides">
						<div v-if="chosenMarket === 900">
							<div v-for="st in stationsInMarket" :key="st.id" style="display: inline-block; margin-bottom: 8px;">
								<input style="margin-right: 5px;" type="checkbox" v-model="chosenStations" :id="'stn'+st.id" :value="st.id" />
								<label style="margin-right: 15px;" :for="'stn'+st.id">{{st.name}}</label>
							</div>
						</div>
						<div v-if="chosenMarket !== 900">
							<div style="margin-bottom: 20px;">
								<div v-for="st in allStationsInMarket" :key="st.id" style="display: inline-block; margin-bottom: 8px;">
									<input style="margin-right: 5px;" type="checkbox" v-model="chosenStations" :id="'stn'+st.id" :value="st.id" />
									<label style="margin-right: 15px;" :for="'stn'+st.id">{{st.name}}</label>
								</div>
							</div>
							<div v-for="st in combosInMarket" :key="st.id" style="display: inline-block; margin-bottom: 8px;">
								<input style="margin-right: 5px;" type="checkbox" v-model="chosenStations" :id="'stn'+st.id" :value="st.id" />
								<label style="margin-right: 15px;" :for="'stn'+st.id">{{st.name}}</label>
							</div>
						</div>
					</div>
				</div>
			</div>

		</div>

		<div class="chart-rows">
			<ChartHolderDash charttitle="" source="" :hidesource="true" :height="500" :showzoomoption="false" :hideheadsection="true">
				<template v-slot:default>
					<ApexFreqLineTrendMultiAudology v-if="store.returnedData && store.returnedData.length > 0"
						:basedata="chartDataFiltered" :chosendaypart="chosenDaypart" :chosendemo="chosenDemographic" :chosenstatistic="chosenStatistic"
						:chartstationlist="chartStationObs" :surveylist="surveyListFiltered" :chosenradiotype="chosenRadioType"
					></ApexFreqLineTrendMultiAudology>
					<ChartLoaderInsert v-if="getChartsBtnWorking"></ChartLoaderInsert>
				</template>
			</ChartHolderDash>
		</div>

		<div class="gfktable">
			<div id="table_div">
				<div id="table_hold">
					<div class="table-container">
						<table>
							<thead>
								<tr>
									<td class="stnfix"></td>
									<td class="stnpad"></td>
									<td v-for="s in surveyListIdOldToNew" :key="s">{{getSurveyNiceNameFromId(s)}}</td>
								</tr>
							</thead>
							<tbody>
								<tr v-for="cd in chartStationObs" :title="cd.name" :key="cd.id">
									<td class="stnfix">{{cd.name}}</td>
									<td class="stnpad"></td>
									<td v-for="(s, index) in surveyListIdOldToNew" :key="index">{{tblCellNumDisplay(getDataPoint(s, cd.id))}}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="highlight-toggles">
					<span onclick="highlightByRow();" class="link-text" style="margin-right: 5px; font-size: 12px;">Highlight By Row</span>
					<span onclick="highlightByCol();" class="link-text" style="margin-right: 5px; font-size: 12px;">Highlight By Column</span>
					<span onclick="clearHighlights();" class="link-text" style="font-size: 12px;">Clear Highlights</span>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import ChartHolderDash from "@/components/ChartHolderDash";
import ChartLoaderInsert from "@/components/ChartLoaderInsert";
import ApexFreqLineTrendMultiAudology from "@/components/ApexFreqLineTrendMulti.vue";

export default {
	name: 'RatingsChartsAud',
	components: {
		ApexFreqLineTrendMultiAudology,
		ChartLoaderInsert,
		ChartHolderDash,
	},
	props: {
		iscra: {
			type: Boolean,
			default: false
		},
	},
	mixins: [globalMixin],
	data() {
		return {
			store: store,
			getChartsBtnWorking: false,
			chartsSource: [],
			chartsTitle: [],
			cadence: 'survey', //survey, year
			chosenStartYear: 2021,
			chosenEndYear: 2023,
			chosenRadioType: 0,

			perthDefaultStations: ['g_8902','g_8905','g_8914','g_901012'],
			adelaideDefaultStations: ['g_8201','g_8203','g_801056','g_8213','g_8214','g_8204'],
			brisbaneDefaultStations: ['g_7203','g_7206','g_7214','g_701056'],
			melbourneDefaultStations: ['g_3207','g_3208','g_3217','g_3223','g_301056','g_301057'],
			sydneyDefaultStations: ['g_2207','g_2209','g_2217','g_201009','g_201056','g_2222'],
			goldcoastDefaultStations: ['g_4817','g_427001','g_427036'],
			canberraDefaultStations: ['g_4001','g_4002','g_4004','g_402003'],
			newcastleDefaultStations: ['g_4301','g_4304','g_403003','g_403011'],
			smbapDefaultStations: [
				'c_2014', //SCA total network excl DAB
				'c_2037', //NovaEnt network excl DAB
				'c_2013', //ARN Network excl DAB
				'c_2042', //Nine network
				'c_109', //Ace network
			],
			totalDefaultStations: ['c_2129','c_117','c_2132','c_207','c_2128','c_124','c_2127'],

			chosenMarket: 900,
			chosenDemographic: 48,
			chosenDemoGroup: 'People',
			chosenDaypart: 2,
			chosenStations: ['c_2129','c_117','c_2132','c_207','c_2128','c_124','c_2127'],
			chosenStatistic: 'shareToAll',
			chosenFirstSurvey: "",
			chosenLastSurvey: "",

			chartoptions: {
				chart: {
					id: "ratings",
					toolbar: {
						show: true
					},
					animations: {
						enabled: false
					}
				},
				xaxis: {
					type: "category",
					categories: [],
					tickAmount: 10,
					labels: {
						show: true,
						hideOverlappingLabels: true
					},
					axisTicks: {
						show: true
					}
				},
				toolbar: {
					autoSelected: "zoom"
				},
				yaxis: {
					decimalsInFloat: 1,
					min: 0,
				},
				colors: [],
				stroke: {
					curve: "straight",
					width: 3,
				},
				dataLabels: {
					enabled: false
				},
				markers: {
					size: 4
				},
			},
			mysurveylist: [],
			mycatnames: [],

			showStationSelectOverlay: false,

			marketList: [
				{id: 110, name: 'Sydney'},
				{id: 111, name: 'Melbourne'},
				{id: 112, name: 'Brisbane'},
				{id: 113, name: 'Adelaide'},
				{id: 114, name: 'Perth'},
				{id: 900, name: 'SMBAP'},
				{id: 19, name: 'Newcastle'},
				{id: 14, name: 'Gold Coast'},
				{id: 72, name: 'Canberra'},
			],
			demoGroups: [
				{key: 'People', dids: [48,78,255,535,110,64,65,372,9027,1326,263,66,67,9004,458,181,68,98,9225,63,182]},
				{key: 'Males', dids: [49,151,256,9056,69,803,117,373,9028,1321,265,238,71,9006,9039,271,158,196,9226,9023,273]},
				{key: 'Females', dids: [50,152,257,9055,72,532,118,374,2316,1322,266,239,74,9005,9038,272,159,195,9227,9022,274]},
			],
			demoGroupsYoYSMBAP: [
				{key: 'People', dids: [48,65,66,78,67,68,255,535,110,98,9225]},
				{key: 'Males', dids: [49]},
				{key: 'Females', dids: [50]},
			],
			daypartIds: [1,2,3,4,5,6,7,8,18,19,20,23,24,26,28,29,30,38,40,47],
			nRecentSurveys: 20,

			comboIdsForMarket: [
				'c_117', //All Commercial
				'c_207', //All Commercial DAB Only
				'c_206', //All Commercial excl DAB
				'c_2127', //all commercial AM
				'c_2128', //all commercial FM
				'c_2129', //all radio
				'c_2130', //all radio FM
				'c_2131', //all radio AM
				'c_2132', //all radio DAB
				'c_124', //ABC Total Network
				'c_389', //ABC Total DAB Only
				'c_2027', //ABC Total excl DAB
			],
		}
	},
	computed: {
		startYear() {
			if([14,19,72].includes(this.chosenMarket)) return 2009
			return 2004
		},
		endYear() {
			return new Date().getFullYear()
		},
		yearOptions() {
			let y = []
			for(let i=this.endYear; i>=this.startYear; i--) {
				y.push(i)
			}
			return y
		},
		demoGroupsShortcut() {
			if(this.cadence === 'year' && this.chosenMarket === 900) return this.demoGroupsYoYSMBAP
			return this.demoGroups
		},
		markets() {
			return this.store.marketObs.filter(item => item.isGfk === true)
		},
		allDemoIds() {
			let d = []
			for(let did of this.demoGroupsShortcut) {
				for(let dd of did.dids) {
					d.push(dd)
				}
			}
			return d
		},
		demos() {
			return this.store.demoObs.filter(item => this.allDemoIds.includes(item.id))
		},
		demoDisplay() {
			let dg = this.demoGroupsShortcut.find(i => i.key === this.chosenDemoGroup)
			if(dg) {
				return this.store.demoObs.filter(item => dg.dids.includes(item.id))
			}
			return []
		},
		dayparts() {
			if(this.store.daypartObs) {
				return this.store.daypartObs.filter(item => this.daypartIds.includes(item.id))
			}
			return []
		},
		stationIdsInMarket() {
			if(this.chosenMarket === 900) {
				return [
					'c_2117', //nova fm excl dab
					'c_2030', //kiis excl dab
					'c_2034', //hit excl dab
					'c_2041', //smooth excl dab
					'c_2032', //pure gold excl dab
					'c_2035', //triple M excl dab
					'c_120', //triple j
					'c_110', //ARN Total Network
					'c_2028', //ARN Total DAB Only
					'c_2013', //ARN Network excl DAB
					'c_227', //ARN Representation
					'c_2016', //ARN Representation excl DAB
					'c_2042', //Nine network
					'c_2036', //NovaEnt network
					'c_2038', //NovaEnt network DAB only
					'c_2037', //NovaEnt network excl DAB
					'c_105', //SCA total network
					'c_2033', //SCA total network DAB only
					'c_2014', //SCA total network excl DAB
					'c_226', //SCA representation
					'c_2015', //SCA representation excl DAB
					'c_109', //Ace network
					'c_117', //All Commercial
					'c_207', //All Commercial DAB Only
					'c_206', //All Commercial excl DAB
					'c_2127', //all commercial AM
					'c_2128', //all commercial FM
					'c_2129', //all radio
					'c_2130', //all radio FM
					'c_2131', //all radio AM
					'c_2132', //all radio DAB
					'c_124', //ABC Total Network
					'c_389', //ABC Total DAB Only
					'c_2027', //ABC Total excl DAB
				]
			}
			return this.store.stationObs.filter(item => item.mktid === this.chosenMarket).map(item => item.id)
		},
		combosInMarket() {
			if(this.chosenMarket === 900) return []
			return this.store.stationObs.filter(item => this.comboIdsForMarket.includes(item.id))
		},
		allStationsInMarket() {
			return this.store.stationObs.filter(item => item.mktid === this.chosenMarket);
		},
		stationsInMarket() {
			if(this.chosenMarket === 900) {
				return this.store.stationObs.filter(item => this.stationIdsInMarket.includes(item.id))
			}
			return this.store.stationObs.filter(item => item.mktid === this.chosenMarket && ['am','fm'].includes(item.band));
		},
		chartStationObs() {
			if(this.store.stationObs.length > 0) {
				return this.store.stationObs.filter(item => this.chosenStations.includes(item.id))
			}
			return []
		},
		activeMarketOb() {
			return this.store.marketObs.find(item => item.id === this.chosenMarket)
		},
		surveyList() {
			if(this.cadence === 'year') {
				let ss = []
				let currentYear = new Date().getFullYear()
				let startYear = 2004
				if([14,19,72].includes(this.chosenMarket)) startYear = 2009
				for(let y=currentYear; y>=startYear; y--) {
					ss.push({
						id: 'CY'+y.toString(),
						mktid: this.chosenMarket,
						name: 'CY'+y.toString(),
						nicename: 'CY'+y.toString(),
					})
				}
				return ss
			}
			if(this.activeMarketOb) {
				return this.store.surveyObs.filter(item => item.mktid === this.activeMarketOb.id)
			}
			return []
		},
		surveyListIds() {
			let ss = []
			for(let su of this.surveyList) {
				ss.push(su.id)
			}
			return ss
		},
		filteredSurveyIds() {
			if(this.surveyListIds.length === 0) return []
			let s = []
			for(let myid of this.surveyListIds) {
				let syr = parseInt(myid.substr(2,4))
				if(syr >= this.chosenStartYear && syr <= this.chosenEndYear) {
					s.push(myid)
				}
			}
			return s
		},
		chartDataFiltered() {
			if(!this.store.returnedData || this.store.returnedData.length === 0) return []
			return this.store.returnedData.filter(item => this.filteredSurveyIds.includes(item.survcode))
		},
		surveyListFiltered() {
			return this.surveyList.filter(item => this.filteredSurveyIds.includes(item.id))
		},
		surveyListIdOldToNew() {
			return JSON.parse(JSON.stringify(this.filteredSurveyIds)).sort()
		},
		tableData() {
			let data = {}
			for(let item of this.chartDataFiltered) {
				let survcode = item.survcode
				if(!data[survcode]) {
					data[survcode] = {}
				}
				for(let d of item.data) {
					let stid = d.stid
					data[survcode][stid] = d.val
				}
			}
			return data
		},
	},
	methods: {
		getDataPoint(survcode, stid) {
			if(this.tableData[survcode] && this.tableData[survcode][stid]) return this.tableData[survcode][stid]
			return null
		},
		tblCellNumDisplay(rawval) {
			if(this.chosenStatistic === 'tslHrsDecimal') return this.numdisplay(rawval, 2)
			else if(this.chosenStatistic === 'Share') return this.numdisplay(rawval, 1)
			else if(['avgAud','cume'].includes(this.chosenStatistic)) {
				return rawval/1000
			}
			return rawval
		},
		getSurveyNiceNameFromId(survcode) {
			let surv = this.surveyList.find(item => item.id === survcode)
			if(surv) return surv.nicename
			return survcode
		},
		getChartData() {
			if(this.getChartsBtnWorking === false) {
				this.getChartsBtnWorking = true;
				let self = this
				let sets = []
				let type = 'standard'
				let stnids = this.stationIdsInMarket
				if(this.chosenMarket !== 900) stnids = stnids.concat(this.comboIdsForMarket)

				if(this.chosenMarket === 900 && this.cadence !== 'year') type = 'smbap'
				sets.push({
					type: type,
					dayparts: [this.chosenDaypart],
					demos: [this.chosenDemographic],
					statistics: [this.chosenStatistic],
					surveylist: this.surveyListIds,
					stationIds: stnids,
					market: this.chosenMarket,
					audioTypeIds: [this.chosenRadioType],
					cadence: this.cadence,
				})
				this.doGetCacheDataAudology(sets).then(function(ret) {
					self.getChartsBtnWorking = false
					if(ret.error && ret.error === 1) console.log(ret)
				})
			}
		},
		updateColours() {
			let outcols = [];
			for(let i=0; i<this.store.returnedData.length; i++) {
				let gfkstnob = this.store.returnedData[i].stationob;
				let stnob = this.store.stationObs.find(item => item.id === gfkstnob.stid);
				if(stnob) {
					outcols.push(this.getChartColorForStation(stnob, outcols));
				}
				else { //use the GFK station lookup
					outcols.push(this.getChartColorForStation(gfkstnob, outcols));
				}
			}
			this.chartoptions.colors = outcols;
			this.chartoptions = {...this.chartoptions, ...{
				colors: outcols
			}};
		},
		selectRecentSurveys(n) {
			this.nRecentSurveys = n
		},
		selectAllSurveys() {
			this.nRecentSurveys = 999
		},
	},
	watch: {
		chosenStatistic() {
			this.getChartData();
		},
		chosenDaypart() {
			this.getChartData();
		},
		chosenDemographic() {
			this.getChartData();
		},
		cadence() {
			this.getChartData();
		},
		chosenDemoGroup() {
			this.chosenDemographic = this.demoDisplay[0].id
		},
		chosenRadioType() {
			this.getChartData();
		},
		chosenMarket() {
			this.chosenStations = this.totalDefaultStations
			// if(this.chosenMarket === 114) this.chosenStations = this.perthDefaultStations;
			// if(this.chosenMarket === 113) this.chosenStations = this.adelaideDefaultStations;
			// if(this.chosenMarket === 112) this.chosenStations = this.brisbaneDefaultStations;
			// if(this.chosenMarket === 111) this.chosenStations = this.melbourneDefaultStations;
			// if(this.chosenMarket === 110) this.chosenStations = this.sydneyDefaultStations;
			// if(this.chosenMarket === 900) this.chosenStations = this.smbapDefaultStations;
			// if(this.chosenMarket === 14) this.chosenStations = this.goldcoastDefaultStations;
			// if(this.chosenMarket === 72) this.chosenStations = this.canberraDefaultStations;
			// if(this.chosenMarket === 19) this.chosenStations = this.newcastleDefaultStations;
			this.getChartData();
		},
		'store.notYetFirstLoadDone': {
			handler() {
				if(this.store.notYetFirstLoadDone === false) this.getChartData()
			}
		},
	},
	mounted() {
		this.chosenEndYear = this.endYear
		if(this.store.notYetFirstLoadDone === false) {
			this.getChartData()
		}
	},
	created() {
		//this.getMeta();
		document.title = "Ratings Charts";
	},
}
</script>

<style scoped>
.netshortcut {
	display: inline-block;
	border: 1px solid #CCC;
	background: #EAEAEA;
	cursor: pointer;
	transition: background-color 0.2s ease-in-out;
	margin: 0 10px 10px 0;
	padding: 7px 10px;
	border-radius: 5px;
}
.netshortcut:hover {
	background-color: #DDD;
}
.loader-overlay {
	height: 100%;
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1001;
	background: rgba(0,0,0,0.2);
	text-align: center;
	display: flex;
	justify-content: center;
}
.stationselect-popup {
	width: 70%;
	height: 500px;
	margin: 100px auto;
	position: relative;
}
.stationselect-popup.whitebg {
	background-color: #FFF;
	overflow-y: auto;
}
.popup-topbar {
	position: absolute;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: rgb(239, 239, 239);
	border-bottom: 1px solid rgb(204, 204, 204);
	color: #000;
	padding: 0 20px;
	width: 100%;
	height: 40px;
}
.closer {
	font-size: 18px;
	cursor: pointer;
}
.closer:hover i {
	color: #888;
}
.popup-insides {
	width: 100%;
	padding: 20px;
	height: calc(100% - 40px);
	margin-top: 40px;
	overflow-y: auto;
}
/** gfk ratings table styling **/
.gfktable .table-container {
	overflow-x: auto;
	padding: 10px 0 20px 0;
}
.gfktable #table_hold {
	padding: 0 30px;
}
.gfktable .highlight-toggles {
	padding: 10px 30px;
}
.gfktable #table_hold h1 {
	font-size: 22px;
}
.gfktable #table_hold table {
	font-size: 13px;
	border-collapse: collapse;
}
.gfktable #table_hold table thead {
	background: #EFEFEF;
	font-weight: bold;
	font-size: 10px;
	text-transform: uppercase;
}
.gfktable #table_hold td {
	text-align: center;
	padding: 5px 8px;
	border: 1px solid #CCC;
}
.gfktable #table_hold td.lft {
	text-align: left;
}
.gfktable .stnname {
	width: 150px;
}
.gfktable #table_hold thead td.stnfix {
	background: #EFEFEF;
	height: 30px;
	border-bottom: none;
}
.gfktable #table_hold td.stnfix {
	position: absolute;
	width: 185px;
	text-align: left;
}
.gfktable #table_hold table tr td.stnpad {
	padding-left: 175px;
}
.gfktable #table_hold table tbody tr:nth-of-type(odd) {
	background-color: #F9F9F9;
}
.gfktable #table_hold table tbody tr:nth-of-type(odd) .stnfix {
	background-color: #F9F9F9;
}
.gfktable #table_hold table tbody tr:nth-of-type(even) .stnfix {
	background-color: #FFF;
}
.gfktable #table_hold table tbody tr:hover,
.gfktable #table_hold table tbody tr:hover .stnfix {
	background-color: #EEE;
}
.gfktable .high {
	background-color:#23B723;
}
.gfktable .high2 {
	background-color: #48DC48;
}
.gfktable .high3 {
	background-color: #7FE881;
}
.gfktable .high4 {
	background-color: #C0F5C1;
}
.link-text {
	cursor:pointer;
}
.link-text:hover {
	text-decoration: underline;
}
/** end gfk ratings table styling **/
</style>
